/* MAIN CONTAINER */
.home-container {
    color: black;
    padding-top: 2rem;
    padding-bottom: 4rem;
    font-family: 'Josefin Sans', sans-serif;
}

/* FIRST SECTION - FLEX CONTAINER */
.home-main {
    display: flex;
    flex-direction: column;
    padding: 1rem  4rem;
}

/* ROW WHEN SMALl, COLUMNS WHEN LARGER */
@media (min-width:800px) {
    .home-main {
        flex-direction: row;
    }
} 

/* LEFT COLUMN - FLEXBOX CHILD + GRID CONTAINER */
.home-left{
    flex: 1;
    width: 100%;
}


/* GRID ITEM WITHIN FLEXBOX COLUMN */
.grid-cont {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(4, 1fr);

}
/* COlUMN 1 - GRID ITEM */
.main-grid-item {
width: 100%;
}

/* noodle */
.main-grid-item:nth-of-type(1){ 
    grid-column: 6 / 8;
    grid-row: 4 / 5;
    position: relative;
    transform: rotate(145deg);
    left: 25%;
    top: 20%;
}

/* hiker */
.main-grid-item:nth-of-type(2){
    grid-column: 1 / 4;
    grid-row: 1 / 2;
    transform: rotate(100deg);
    position:relative;
    width: 50%;
    left: 10%;
    top: 10%;
}

.hiker {
    width: 90%;
    border-radius: 10px;
} 

/* hiker */
.main-grid-item:nth-of-type(3){
    grid-column: 2 / 6;
    grid-row: 1 / 4;  
}

/*  hiker*/
.main-grid-item:nth-of-type(4){
    grid-column: 4 / 8;
    grid-row: 2 / 4;
    position: relative;
    top: 40%;
}

/* green */
.main-grid-item:nth-of-type(5){
    grid-column: 4 / 6;
    grid-row: 2 / 4;  
    position: relative;
    align-self: center;
    width: 70%; 
    left: 10%;
    transform: rotate(50deg);
} 

/* squiggle */
.main-grid-item:nth-of-type(6){ 
    grid-column: 1 / 3; 
    grid-row: 3 / 5;  
    transform: rotate(20deg);
    position: relative;
    top: 50%;
}

/* dot  */
.main-grid-item:nth-of-type(7){ 
    grid-column: 2 / 4; 
    grid-row: 3 / 4;  
    position: relative;
    align-self: center;
    width: 25%;
    left:30%;
    top: 35%;
    transform: rotate(-55deg)
}


/* RIGHT COLUMN - FLEX CONTAINER*/
.home-right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media (min-width:800px) {
    .home-right {
        width: 40%;
    }

    .home-left {
        width: 60%;
    }
    .title-container{
        width: 100%;
        text-align: center;
    }
} .title-container {
    font-size: 3rem;
    line-height: 1;
    padding: 4rem;
}

.intro-item .purple-graphic {
    width: 20%;
}


/* HOW TO USE BEFORE SVGS */
.how-to-use {
    padding: 2rem;
    background-color:  rgb(26, 33, 120);
    color: whitesmoke;
    font-family: 'Josefin Sans', sans-serif;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}



/* SVG SECTION */
.svg-section{
    background-color: rgb(237, 224, 193);
    display: flex;
    flex-direction: column;
}

.svg-container{
    flex: 1;
    border: 1px solid black;
    padding: 1rem;
}
.svg-item, .compact-use {
    flex: 1;
} 
/* ROW WHEN SMALl, COLUMNS WHEN LARGER */
@media (min-width:800px) {
    .svg-section {
        flex-direction: row;
    }
} 

.compact-use{
    background-color:  rgb(26, 33, 120);
    color: whitesmoke;
    padding: 2rem;
    font-size: 1.5rem;
}

.three-ways{
    display: flex;
    flex-direction: row;
    color: whitesmoke;
    padding: 2rem;
    font-size: 1.5rem;
    align-items:flex-start;
}

.how-item{
    text-align: center;
    border: 1px solid pink;
    flex: 1;
    padding: 3rem;
}


.divider{
    background-color:  rgb(26, 33, 120);
    background-image: url(../images/organic_3_squiggly.svg);
    background-size: 30%;
    background-position: center 50%;
    min-height: 5rem; 
    padding: 4rem;
    text-align: right;
    line-height: 5rem; 
}

/* IMAGE CREDITS */
.home-credits {
    background-image: url(../images/orange heart.svg);
    background-size: 5%;
    background-position: center 50%;
    background-repeat:no-repeat;
    min-height: 10rem;
    padding: 1rem;
    text-align: right;
    line-height: 0.5rem;
    font-family: Arial;
    font-size: 0.7rem;
}
