#outer-flame{
    transform-origin: bottom center;
    animation: grow 4s infinite alternate;
    animation-timing-function: ease-out;
}

#inner-flame{
    transform-origin: bottom center;
    animation: grow 4s infinite alternate;
    animation-timing-function: ease-out;
}

@keyframes grow {
    0% {transform: rotate(-1deg) ;
        opacity: 0.85; }
    20% {transform: rotate(1deg); 
        opacity: 0.9; }
    40% {transform: rotate(-1deg);
        opacity: 0.6;}
    60% {transform: rotate(1deg);
        opacity: 1;}
    80% {transform: rotate(-1deg);
        opacity: 0.9;}
    100% {transform: rotate(1deg);
        opacity: 0.85;}
  }


  @keyframes grow2 {
    from {
      opacity: 1; 
      transform: scale(.9) rotate(2deg);
    }
    to {
      opacity: .9;
      transform: scale(1) rotate(0deg);
    }
  }

  
  