/* MAIN CONTAINER */
.about-container{
    color: black;
    padding-bottom: 4rem; 
    font-family: Geneva, sans-serif
}

/* FLEX CONTAINER */
.about-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0rem 2rem;
}

/* ROWS WHEN SMALL --> COLUMNS WHEN LARGER */
@media (min-width:600px) {
    .about-main {
        flex-direction: row;
    }
} 

.about-main * > {
    flex: 1;
}

.left {
    width: 100%;
}

.right {
    width: 100%;
}

/* TAKE UP MORE SPACE WHEN SMALL --> SPLIT WHEN LARGER */
@media (min-width:600px) {
    .left {
        width: 50%;
    }

    .right {
        width: 50%;
    }
} 

/* LEFT SECTION 1 */
.grid-container {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

.grid-item {
    width: 100%;
    max-height: 100%;
}

.grid-item:nth-of-type(1){
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    place-self: center;
}

.grid-item:nth-of-type(2){
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    top: 5%;
    left: 10%;
    position: relative;
    transform: rotate(190deg);
}

.about-title{
    text-align: center;
}
.about-statement {
    padding: 1rem 3rem 5rem;
    text-align: left;
}

.about-main h3 {
    color: black;
    font-size: 3rem;
    line-height: 1.1;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 700;
    font-style: normal;
}

/* LEFT SECTION 2 */

.background-img-container {
    background-image: url(../images/about/leaf.svg);
    background-repeat: no-repeat;
    background-position: left 25%;
    background-size: 20%;
} 

.guide {
    padding-top: 4rem;
}

.content{
    padding: 1rem 3rem 5rem;
    text-align: left;
}


/* SECTION 2 - RIGHT */


.grid-container-right {
    width: 100%; 
    height: 500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

.grid-item-right {
    width: 100%;
    max-height: 100%;
}

.grid-item-right:nth-of-type(1){
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    place-self: center;
    width: 90%;
}

.grid-item-right:nth-of-type(2){
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    width: 80%;
    position: relative;
}

/* ROW - SECTION 3  */
.row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: flex-start;
    align-items: center;
    padding: 8rem 5rem;
} 

/* ROWS WHEN SMALL --> COLUMNS WHEN LARGER */
@media (min-width:800px) {
    .row {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
} 

/* CHILDREN OF ROW FLEX */
.row > * {
  flex: 1;
} 

.column_1 {
    text-align: center;
    text-justify: inter-word;
}

.column_1 h3 {
 font-size: 2.5rem;
 line-height: 1.1;
 font-weight: 700;
 font-style: normal;

}

.bottom {
    background-color: rgb(86, 125, 96);
    color: whitesmoke;
    padding: 5rem;
    text-transform: uppercase;
    font-family: 'Viaoda Libre', cursive;
}

.bottom p{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Hammersmith One', sans-serif;
    text-align: left;
} 

.title {
    background-image: url(../images/about/pink_squiggle.svg);
    background-size: 100%;
    height: 300px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

}

.title h3 {
    text-align: center;
    color: black;
    font-size: 3rem;
    line-height: 1.1;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 700;
    font-style: normal;
    

}



/* THREE-COLUMN SECTION */

.one, .two, .three {
    width: 100%;
    
}

@media (min-width:600px) {
    .one, .two, .three {
        width: 33.3%;
    }
} 


.section {
    border: 1px solid #000;
    float: left;
    padding: 2rem;
}

/* MAKE BORDER LEFT DISAPPEAR AFTER 600PX TO GET RID OF REPEATING BORDER */
@media (min-width:600px) {
    .section:nth-child(n+2) {
        border-left: 0;
    }
} 

.section sup{
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Viaoda Libre', cursive;
    font-size: 1.8rem;
}

.one, .two {
    border-right: 1px solid gray;
}

/* IMAGE CREDITS */
.credits {
    background-image: url(../images/about/organic_7_flower.svg);
    background-size: 5%;
    background-position: center 50%;
    background-repeat:no-repeat;
    min-height: 10rem;
    padding: 2rem;
    text-align: right;
    line-height: 0.5rem;
}

.credits p{
    font-family: Arial, sans-serif;
    font-size: 9px;
}

/* .about-main img{
    width: 50%,
} */

