.item-container {
    width: 100%;
    font-family: 'Hammersmith One', sans-serif;
    font-size: 1rem;
    line-height: 1.1;
}

/* FLEX CONTAINER */
.item-main {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* ROW WHEN SMALl, COLUMNS WHEN LARGER */
@media (min-width: 800px) {
    .item-main {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }
} 

.item-main .image-left {
    width: 50%; 
    border: 2px solid black;
}

.image-left img{
    display: block;
    width: 100%;
}

.item-main .product-info-right {
    width: 50%; 
}




/* SIMPLIFIED FOR MAIN PAGE ITEMS */
.simplified-item-container{
    width: 100%;
}

.simplified-item-main {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Cormorant Garamond', serif;
}

.simplified-image-left img{
    border: 1px solid black;
    display: block;
    width: 100%;
    max-height: 500px;
}


.simplified-item-name{
font-size: 1.1rem;
font-family: 'Hammersmith One', sans-serif;
text-transform: uppercase;
text-decoration: none;
}

.simplified-product-info-right button{
    width: 100%;
}