*,
*::before,
*::after {
  box-sizing: border-box;
}

body, html {
  text-align: center;
  margin: 0;
  background-color: rgb(245, 245, 238);
  font-weight: 200;
  font-style: normal;
  font-size: 15px;
  letter-spacing: .02em;
  line-height: 1.5em;
  text-transform: none;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  animation: transitionIn 0.75s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

.btn {
  padding: .5rem;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: transparent;
  font-family: 'Cormorant Garamond', serif;
  border: 1px solid black;
  width: 12rem;
  color: black;
}

.btn:hover{
  background-color: orangered;
  color: cornsilk;
  transition: .5s;
}


