#bird1{
    animation: flying 5s infinite;
}

#bird2{
    animation: flying3 5s infinite;
}

#bird3{
    animation: flying 5s infinite;
}

#bird4{
    animation: flying2 5s infinite;
}

#bird5{
    animation: flying 5s infinite;
}


@keyframes flying {
    0% {
        transform: rotate(-2deg);
    }
    50% { 
        transform: rotate(2deg);
    }
    100% { 
        transform: rotate(-2deg);
    }  
}

@keyframes flying2 {
    0% {
        transform: rotate(-1deg);
    }
    50% { 
        transform: rotate(3deg);
    }
    100% { 
        transform: rotate(-1deg);
    }  
  }


  @keyframes flying2 {
    0% {
        transform: rotate(3deg);
    }
    50% { 
        transform: rotate(-1deg);
    }
    100% { 
        transform: rotate(3deg);
    }  
  }

  @keyframes flying3 {
    0% {
        transform: translateY(2em);
    }
    50% { 
        transform: translateY(-2em);
    }
    100% { 
        transform: translateY(2em);
  }
}