.toggle-button {
  display: flex;
  flex-direction: column;
  height: 22px;
  width: 22px;
  background: transparent;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  justify-content: space-around;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__line {
  width: 28px;
  height: 2px;
  transition: all 0.5s ease-in-out;
}

#line1, #line2, #line3  {
  background-color: black;
}

/* HIDDEN MENU BUTTON */
.hide {
  display: none;
}

.open #line1 {
  /* transform-origin: 10px 0px; */
  transform: rotate(-45deg);
  background-color: #43035a;
}

.open #line2 {
  width: 0;
}

.open #line3 {
  transform: translateY(-19px) rotate(45deg);
  background-color: #43035a;
}

@media only screen and (min-width: 1050px) {
  .toggle-button {
      display: none;
  }
}