.header-container{
    width: 100%;
}

.header-main{
    display: flex;
    flex-wrap: nowrap;
    justify-content:space-between;
    align-items: center;
    padding: .5rem 1rem;
}

.siteTitle {
    font-family: 'Viaoda Libre', cursive;
    color:rgb(20,92,77);
    font-size: 3rem;
    cursor: pointer;
}
