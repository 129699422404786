.gear-list-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.gear-list-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* COLUMN WHEN SMALL, ROW WHEN LARGER */
@media (max-width: 800px) {
    .gear-list-flex {
        flex-direction: column;
    }
} 

.gear-list-grid{
    border: 5px solid rgb(26, 33, 120);
    background-color:  rgb(26, 33, 120);
    color: whitesmoke;
    font-family: 'Josefin Sans', sans-serif;
    width: 100%;
    height: 200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    color: black;
    cursor: pointer;
}

.gear-grid-item:nth-of-type(1){
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    place-self: center;
    font-size: 1.5rem;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    line-height: 1;
    width: 80%;
    color: beige;
}

.gear-grid-item:nth-of-type(2){
    grid-column:  1 / 2;
    grid-row: 3 / 4;
width: 30%;
top: 60%;
left: 15%;
position: relative;
transform: rotate(-25deg);
}




.add-new-item-button:hover{
    background-color:  rgb(26, 33, 120);
    color: beige;
}

.list-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 2rem;
}

/* ROW WHEN SMALl, COLUMNS WHEN LARGER */
@media (max-width: 600px) {
    .list-main {
        grid-template-columns: repeat(2, 1fr);
    }
} 



