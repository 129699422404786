
.form-container {
  display: flex;
  justify-content: center;
}

.form-main{
  margin-top: 2rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: 'Cormorant Garamond', serif;
}

.form-title{
  font-size: 2rem;
}

.input-container{
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.input-title{
  text-align: left;
  text-transform: uppercase;
  padding-left: .5rem;
}

.form-input, select {
  margin: .5rem;
  font-size: 1rem;
  padding: .6rem .5rem;
  box-sizing: border-box;
  border: 1px solid black;
  background-color: #f1f1f1;
  border-radius: 3px;
  min-width: 300px;
  
}



/* ROW WHEN SMALl, COLUMNS WHEN LARGER */
@media (min-width: 600px) {
  .form-input {
    width: 100%;
  }
} 

.signup_call{
  display: flex;
  justify-items: center;
  align-items: center;
}

.signup_call .create{
  color: black;
}

.signup_call .create:hover{
  color: orangered;
  transition: .6s;
}



/* EDIT FORM */

.edit-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.form-button-container {
  padding: 1rem;
}