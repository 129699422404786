.footer-container{
    background-color: rgb(84, 86, 76);
    color: whitesmoke;
    padding: 4rem 1rem;
    font-family: 'Cormorant Garamond', serif;
    margin-top: auto;
}

.footer-main {
    display: flex;
    margin-bottom: 2rem;
    justify-content: center;
    align-items: center;
}

.footer-main a {
    color: whitesmoke;
    background-color: rgb(84, 86, 76);
    text-decoration: none;
    transition: color 0.2s;
    text-transform: lowercase;
    font-size: 1.2rem;
}

.footer-column {
    flex-basis: 25%;
    flex-grow: 1;
    text-align: center;
}

.footer-main a:hover {
    color: rgb(225, 97, 46);
}

@media only screen and (max-width: 768px) {
    .footer-main {
        flex-direction: column;
    }
}