.hide{
    display: none;
}
/* MAIN CONTAINER */
.nav-container{
    padding: .5rem;
    width: 100%;
    font-family: 'Hammersmith One', sans-serif;
    animation: transitionLeft 0.75s;
}


@keyframes transitionLeft {
  from {
    opacity: 1;
    transform: translateX(90px);
    transform: rotateX(0);
  }

  to {
    opacity: 1;
    transform: translateX(0);
    transform: rotateX(0);
  }
}

/* MENU ROW */
.menu {
    padding: .6rem 2rem;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    cursor: pointer;
}

@media only screen and (max-width: 1050px) {
    .menu {
        display: none;
    }
}

.item {
    font-weight: 700;
    font-style: normal;
    font-size: .9rem;
    letter-spacing: .05em;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    white-space: nowrap;
    line-height: 1em;
}

.item:hover {
    color: rgb(225, 97, 46);
}


/* SHOW SIDEBAR */

.modal-background{
    width: 100vw;
    height: 100vh;
    background-color: #1e2525cc;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    transition-duration: 0.3s;
    position: fixed;    
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index:100;
}

/* 
FLEX CONTAINER SIDEBAR */
.nav-container .sidebar-main {
    position: fixed;
    top: 0;
    width: 60vw;
    padding: 1.5rem;
    transition: 0.5s ease;
    height: 100vh;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    background-color: rgb(245, 245, 238);
}

.sidebar-main .first-row {
    display: flex;
    font-family: 'Cormorant Garamond', serif;
    font-size: 1.5rem;
    justify-content: space-between;
    padding: 1rem;
}

/* SHOW SIDEBAR - MENU COLUMN */
.sidebar-main .menu-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}

.menu-column .item{
    padding: 2rem 1rem;
    font-size: 1rem;
}